
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { isEmpty } from '@/utils/misc';
import { BendPerformanceData } from '@/models/Charts/chartsData';

@Component
export default class BendPerformance extends Vue {
  @Prop({ required: true })
  private data!: BendPerformanceData[] | null;

  private currentValues: BendPerformanceData | null = null;
  private previousValues: BendPerformanceData | null = null;

  @Watch('data')
  private async updatePerformanceData() {
    if (isEmpty(this.data)) {
      this.currentValues = null;
      this.previousValues = null;
    } else {
      const primary = this.data!.filter((x) => x.isprimary === 1);
      this.currentValues = primary.length === 1 ? primary[0] : null;
      const secondary = this.data!.filter((x) => x.isprimary === 0);
      this.previousValues = secondary.length === 1 ? secondary[0] : null;
    }
  }
}
